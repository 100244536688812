import { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import enFlag from "./assets/flag-en.png";
import ptFlag from "./assets/flag-pt.png";
import "./i18n";
import i18next from "./i18n";
import logo from "./logo.svg";
import LanguageDetectorModule from "i18next";

function App() {
  // detect default browser, system or location language
  // and set acordingly.
  const [currLang, setCurrLang] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    const detectedLang = LanguageDetectorModule.language.split("-")[0];
    document.title = t("page_title");
    setCurrLang({
      id: detectedLang,
      flag: detectedLang === "en" ? enFlag : ptFlag,
    });
  }, [t]);

  function handleChangLang() {
    if (currLang.id === "en") {
      i18next.changeLanguage("pt");
      document.title = t("page_title");
      setCurrLang({ id: "pt", flag: ptFlag });
    } else {
      i18next.changeLanguage("en");
      document.title = t("page_title");
      setCurrLang({ id: "en", flag: enFlag });
    }
  }

  function getThemeIcon() {
    return localStorage.theme === "dark" ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="w-5 h-5"
      >
        <path d="M12 2.25a.75.75 0 01.75.75v2.25a.75.75 0 01-1.5 0V3a.75.75 0 01.75-.75zM7.5 12a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM18.894 6.166a.75.75 0 00-1.06-1.06l-1.591 1.59a.75.75 0 101.06 1.061l1.591-1.59zM21.75 12a.75.75 0 01-.75.75h-2.25a.75.75 0 010-1.5H21a.75.75 0 01.75.75zM17.834 18.894a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 10-1.061 1.06l1.59 1.591zM12 18a.75.75 0 01.75.75V21a.75.75 0 01-1.5 0v-2.25A.75.75 0 0112 18zM7.758 17.303a.75.75 0 00-1.061-1.06l-1.591 1.59a.75.75 0 001.06 1.061l1.591-1.59zM6 12a.75.75 0 01-.75.75H3a.75.75 0 010-1.5h2.25A.75.75 0 016 12zM6.697 7.757a.75.75 0 001.06-1.06l-1.59-1.591a.75.75 0 00-1.061 1.06l1.59 1.591z" />
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="w-5 h-5"
      >
        <path
          fillRule="evenodd"
          d="M9.528 1.718a.75.75 0 01.162.819A8.97 8.97 0 009 6a9 9 0 009 9 8.97 8.97 0 003.463-.69.75.75 0 01.981.98 10.503 10.503 0 01-9.694 6.46c-5.799 0-10.5-4.701-10.5-10.5 0-4.368 2.667-8.112 6.46-9.694a.75.75 0 01.818.162z"
          clipRule="evenodd"
        />
      </svg>
    );
  }

  const [currTheme, setCurrTheme] = useState(getThemeIcon());

  function handleChangeTheme() {
    const htmlElement = document.documentElement;

    if (localStorage.theme === "dark") {
      htmlElement.classList.remove("dark");
      localStorage.theme = "light";
    } else {
      htmlElement.classList.add("dark");
      localStorage.theme = "dark";
    }

    setCurrTheme(getThemeIcon());
  }

  return (
    <Suspense fallback={null}>
      <div className="bg-light-100 dark:bg-dark-900">
        <nav
          className="fixed top-0 left-0 w-full h-16 flex justify-between items-center px-4 md:px-12 lg:px-28
        bg-light-100/30 dark:bg-dark-900/30 backdrop-blur-md border-b border-slate-100 dark:border-dark-800
        z-50"
        >
          <img src={logo} alt={t("logo_alt")} className="w-28 lg:w-36" />

          <div className="flex items-center space-x-5 md:space-x-6 lg:space-x-7">
            <a
              href="mailto:connect@imariom.com"
              className="inline-flex justify-center items-center space-x-1.5 md:space-x-2 text-sm md:text-base
              hover:text-cyan active:text-cyan hover:underline active:underline text-dark-900 dark:text-light-200
              transition duration-150 ease-linear"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
              </svg>

              <span className="inline md:hidden">{t("email_link_title")}</span>
              <span className="hidden md:inline">connect@imariom.com</span>
            </a>

            <button
              className="p-1 rounded-full text-cyan bg-transparent hover:bg-white active:bg-white 
              dark:hover:bg-slate-800 dark:active:bg-slate-800 border-2 border-transparent hover:border-cyan
              active:border-cyan hover:shadow-md active:shadow-md dark:hover:shadow-gray-600 dark:active:shadow-gray-600
              transition duration-300 ease-linear"
              title={t("toggle_theme_tooltip")}
              onClick={handleChangeTheme}
            >
              {currTheme}
            </button>
          </div>
        </nav>

        <div
          className="mt-24 flex flex-col justify-center items-center
          px-3.5 md:px-12 lg:px-28 space-y-3 lg:space-y-5 "
        >
          <h1
            className="inline py-2 text-4xl lg:text-5xl font-black text-center text-transparent
            bg-clip-text bg-gradient-to-r from-cyan to-pink"
          >
            {t("hero_title")}
          </h1>
          <div className="w-full md:w-3/5 mx-auto px-3.5 md:px-0 flex flex-col justify-center items-center space-y-8 text-dark-900 dark:text-light-200">
            <p className="text-base lg:text-lg leading-7  text-center">
              {t("hero_para_1")}
              <a
                href="https://twitter.com/__imariom"
                className="underline text-cyan hover:text-orange hover:no-underline active:no-underline
                transition duration-150 ease-linear"
              >
                Twitter
              </a>
              {t("hero_para_2")}
              <a
                href="https://www.linkedin.com/in/m%C3%A1rio-moiane-5aa424202/?lipi=urn%3Ali%3Apage%3Ad_flagship3_feed%3BJPe1g2U2T1WAbNW%2FM5miPg%3D%3D"
                className="underline text-cyan hover:text-orange hover:no-underline active:no-underline
                transition duration-150 ease-linear"
              >
                Linkedin
              </a>
              {t("hero_para_3")}
            </p>

            <div className="w-full space-y-4">
              <form className="w-full flex flex-col lg:flex-row justify-center items-center space-y-5 lg:space-y-0 lg:space-x-5">
                <input
                  type="text"
                  placeholder={t("notify_input")}
                  className="py-2.5 md:py-2 px-3.5 lg:px-5 rounded-full bg-light-50 dark:bg-dark-800
                    border-2 border-gray-100 dark:border-transparent hover:border-cyan focus:border-cyan
                    hover:shadow-md dark:hover:shadow-slate-600 focus:shadow-md dark:focus:shadow-slate-600
                    text-xs md:text-base outline-none transition duration-300 ease-out"
                />
                <button
                  type="submit"
                  className="flex justify-center items-center space-x-2
                    py-1.5 lg:py-2 px-3.5 lg:px-3 rounded-full hover:shadow-md dark:hover:shadow-slate-600 focus:shadow-md dark:focus:shadow-slate-600
                    bg-gradient-to-r from-cyan to-pink hover:to-cyan text-light-50 font-semibold
                    transition duration-300 ease-out"
                >
                  <p className="text-sm md:text-normal lg:text-lg">
                    {t("notify_btn")}
                  </p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.25 9a6.75 6.75 0 0113.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 01-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 11-7.48 0 24.585 24.585 0 01-4.831-1.244.75.75 0 01-.298-1.205A8.217 8.217 0 005.25 9.75V9zm4.502 8.9a2.25 2.25 0 104.496 0 25.057 25.057 0 01-4.496 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </form>
              <p className="text-sm text-center text-light-300">
                {t("notify")}
              </p>
            </div>
          </div>
        </div>

        <footer className="absolute bottom-0 w-full px-4 md:px-12 lg:px-28">
          <div
            className="flex flex-col md:flex-row-reverse justify-between items-center space-y-4 md:space-y-0 py-5 
              md:pt-5 text-light-300 dark:text-light-200 border-t border-slate-100 dark:border-dark-800"
          >
            <div className="flex justify-end items-center space-x-5 md:space-x-6 lg:space-x-7 divide-x-2 divide-slate-100 dark:divide-dark-800">
              <div className="flex justify-end items-center space-x-3 md:space-x-3.5">
                <a
                  href="https://twitter.com/__imariom"
                  target="_blank"
                  rel="noreferrer"
                  className="p-1 rounded-full hover:text-cyan bg-transparent hover:bg-white active:bg-white 
                  dark:hover:bg-slate-800 dark:active:bg-slate-800 border-2 border-transparent hover:border-cyan
                  active:border-cyan hover:shadow-md active:shadow-md dark:hover:shadow-gray-600 dark:active:shadow-gray-600
                  transition duration-300 ease-linear"
                >
                  <svg
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.995 6.68799C20.8914 6.15208 21.5622 5.30823 21.882 4.31399C21.0397 4.81379 20.118 5.16587 19.157 5.35499C17.8246 3.94552 15.7135 3.60251 14.0034 4.51764C12.2933 5.43277 11.4075 7.37948 11.841 9.26999C8.39062 9.09676 5.17598 7.4669 2.99702 4.78599C1.85986 6.74741 2.44097 9.25477 4.32502 10.516C3.64373 10.4941 2.97754 10.3096 2.38202 9.97799C2.38202 9.99599 2.38202 10.014 2.38202 10.032C2.38241 12.0751 3.82239 13.8351 5.82502 14.24C5.19308 14.4119 4.53022 14.4372 3.88702 14.314C4.45022 16.0613 6.06057 17.2583 7.89602 17.294C6.37585 18.4871 4.49849 19.1342 2.56602 19.131C2.22349 19.1315 1.88123 19.1118 1.54102 19.072C3.50341 20.333 5.78739 21.0023 8.12002 21C11.3653 21.0223 14.484 19.7429 16.7787 17.448C19.0734 15.1531 20.3526 12.0342 20.33 8.78899C20.33 8.60299 20.3257 8.41799 20.317 8.23399C21.1575 7.62659 21.8828 6.87414 22.459 6.01199C21.676 6.35905 20.8455 6.58691 19.995 6.68799Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </a>
                <a
                  href="https://github.com/imariom"
                  target="_blank"
                  rel="noreferrer"
                  className="p-1 rounded-full hover:text-cyan bg-transparent hover:bg-white active:bg-white 
                  dark:hover:bg-slate-800 dark:active:bg-slate-800 border-2 border-transparent hover:border-cyan
                  active:border-cyan hover:shadow-md active:shadow-md dark:hover:shadow-gray-600 dark:active:shadow-gray-600
                  transition duration-300 ease-linear"
                >
                  <svg
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.026 2C7.13295 1.99937 2.96183 5.54799 2.17842 10.3779C1.395 15.2079 4.23061 19.893 8.87302 21.439C9.37302 21.529 9.55202 21.222 9.55202 20.958C9.55202 20.721 9.54402 20.093 9.54102 19.258C6.76602 19.858 6.18002 17.92 6.18002 17.92C5.99733 17.317 5.60459 16.7993 5.07302 16.461C4.17302 15.842 5.14202 15.856 5.14202 15.856C5.78269 15.9438 6.34657 16.3235 6.66902 16.884C6.94195 17.3803 7.40177 17.747 7.94632 17.9026C8.49087 18.0583 9.07503 17.99 9.56902 17.713C9.61544 17.207 9.84055 16.7341 10.204 16.379C7.99002 16.128 5.66202 15.272 5.66202 11.449C5.64973 10.4602 6.01691 9.5043 6.68802 8.778C6.38437 7.91731 6.42013 6.97325 6.78802 6.138C6.78802 6.138 7.62502 5.869 9.53002 7.159C11.1639 6.71101 12.8882 6.71101 14.522 7.159C16.428 5.868 17.264 6.138 17.264 6.138C17.6336 6.97286 17.6694 7.91757 17.364 8.778C18.0376 9.50423 18.4045 10.4626 18.388 11.453C18.388 15.286 16.058 16.128 13.836 16.375C14.3153 16.8651 14.5612 17.5373 14.511 18.221C14.511 19.555 14.499 20.631 14.499 20.958C14.499 21.225 14.677 21.535 15.186 21.437C19.8265 19.8884 22.6591 15.203 21.874 10.3743C21.089 5.54565 16.9181 1.99888 12.026 2Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </a>
                <a
                  href="https://www.linkedin.com/in/m%C3%A1rio-moiane-5aa424202/?lipi=urn%3Ali%3Apage%3Ad_flagship3_feed%3BJPe1g2U2T1WAbNW%2FM5miPg%3D%3D"
                  target="_blank"
                  rel="noreferrer"
                  className="p-1 rounded-full hover:text-cyan bg-transparent hover:bg-white active:bg-white 
                  dark:hover:bg-slate-800 dark:active:bg-slate-800 border-2 border-transparent hover:border-cyan
                  active:border-cyan hover:shadow-md active:shadow-md dark:hover:shadow-gray-600 dark:active:shadow-gray-600
                  transition duration-300 ease-linear"
                >
                  <svg
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13 21H9V9H13V11C13.8526 9.91525 15.1456 9.26857 16.525 9.237C19.0056 9.25077 21.0072 11.2694 21 13.75V21H17V14.25C16.84 13.1326 15.8818 12.3036 14.753 12.306C14.2593 12.3216 13.7932 12.5378 13.4624 12.9046C13.1316 13.2715 12.9646 13.7573 13 14.25V21ZM7 21H3V9H7V21ZM5 7C3.89543 7 3 6.10457 3 5C3 3.89543 3.89543 3 5 3C6.10457 3 7 3.89543 7 5C7 5.53043 6.78929 6.03914 6.41421 6.41421C6.03914 6.78929 5.53043 7 5 7Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </a>
              </div>

              <div className="pl-4 lg:pl-5">
                <button
                  onClick={handleChangLang}
                  className="flex justify-end items-center space-x-3 hover:text-cyan
                      active:text-orange"
                >
                  <img src={currLang.flag} alt={"flag"} className="w-5" />
                  <p className="w-3 text-sm font-bold">{currLang.id}</p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-4 md:w-5 h-4 lg:h-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.755 10.059a7.5 7.5 0 0112.548-3.364l1.903 1.903h-3.183a.75.75 0 100 1.5h4.992a.75.75 0 00.75-.75V4.356a.75.75 0 00-1.5 0v3.18l-1.9-1.9A9 9 0 003.306 9.67a.75.75 0 101.45.388zm15.408 3.352a.75.75 0 00-.919.53 7.5 7.5 0 01-12.548 3.364l-1.902-1.903h3.183a.75.75 0 000-1.5H2.984a.75.75 0 00-.75.75v4.992a.75.75 0 001.5 0v-3.18l1.9 1.9a9 9 0 0015.059-4.035.75.75 0 00-.53-.918z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <p className="text-xs lg:text-sm text-light-200 dark:text-light-300">
              {t("copyright")}
            </p>
          </div>
        </footer>
      </div>
    </Suspense>
  );
}

export default App;
