import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import I18NextXhrBackend from "i18next-xhr-backend";
import LanguageDetectorModule from "i18next-browser-languagedetector";

const fallbackLng = ["en"];
const availableLanguages = ["en", "pt"];

i18next
  .use(I18NextXhrBackend)
  .use(LanguageDetectorModule)
  .use(initReactI18next)
  .init({
    fallbackLng,
    detection: {
      checkWhitelist: true,
    },
    debug: false,
    whitelist: availableLanguages,
    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
  });

export default i18next;
